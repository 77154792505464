import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';
import mediumIcon from '../../images/medium.png';
import { Icon } from '@components/icons';

const StyledBlogsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .blogs-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    align-items: stretch;
  }
`;

const StyledBlog = styled.div`
  cursor: default;
  transition: var(--transition);

  .blog-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 1.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-navy);
    transition: var(--transition);

    .title {
      h2 {
        margin: 0 0 10px;
        color: var(--lightest-slate);
        font-size: var(--fz-xxl);
      }
    }

    .read-more {
      display: flex;
      align-items: center;
      margin-top: 10px;

      a {
        display: flex;
        align-items: center;
        color: var(--green);
        font-size: var(--fz-sm);

        img {
          width: auto;
          max-width: 80px;
          height: 24px;
          margin-right: 10px;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

const Blogs = () => {
  const revealTitle = useRef(null);
  const revealBlogs = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }
    sr.reveal(revealTitle.current, srConfig());
    revealBlogs.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const blogs = [
    {
      title: 'The ZenML VSCode Extension: Bridging Python and TypeScript',
      link: 'https://www.zenml.io/blog/zenml-vscode-extension',
    },
    {
      title: 'Fetching API data without useEffect',
      link: 'https://medium.com/@marwan.zaarab/fetching-api-data-without-useeffect-caf981b0e138',
    },
    {
      title: 'CI Guide: GitHub Actions and Digital Ocean',
      link: 'https://medium.com/@marwan.zaarab/ci-guide-github-actions-and-digital-ocean-f25f112fe90c',
    },
    {
      title: 'Deploying a Multi-Route React App',
      link: 'https://medium.com/@marwan.zaarab/deploying-a-react-app-with-multiple-routes-using-express-static-files-e3373c53fe94',
    },
    {
      title: 'JavaScript Promises: A Guide to Asynchronous Operations',
      link: 'https://medium.com/@marwan.zaarab/promises-in-javascript-2727ea44cc2',
    },
      {
      title: 'Ruby OOP: Exploring Instance & Class Variables, Methods, Scopes, and Self',
      link: 'https://medium.com/@marwan.zaarab/instance-variables-ecb65ffd089f',
    },
    {
      title: 'Ruby OOP Principles: Encapsulation, Inheritance and Polymorphism',
      link: 'https://medium.com/@marwan.zaarab/rb120-oop-part-1-encapsulation-inheritance-and-polymorphism-179e095d26ba',
    },
    {
      title: 'Ruby Closures: Understanding Blocks, Procs, Methods, and Arity',
      link: 'https://medium.com/@marwan.zaarab/closures-blocks-procs-methods-and-arity-in-ruby-c5d55e49bd13',
    },
    {
      title: 'SQL Sublanguages: DDL, DML, and DCL ',
      link: 'https://medium.com/@marwan.zaarab/sql-fc8019488cc9',
    },
    {
      title: 'How does the internet work?',
      link: 'https://medium.com/@marwan.zaarab/how-does-the-internet-work-part-1-db4af9a74bee',
    },
  ];

  return (
    <section id="blog">
      <h2 className="numbered-heading" ref={revealTitle} style={{ marginBottom: 0 }}>
        Blog Posts
      </h2>
      <StyledBlogsSection>
        <div className="blogs-grid">
          {blogs.map((blog, index) => (
            <StyledBlog key={index}>
              <div className="blog-inner">
                <div className="title">
                  <h2>{blog.title}</h2>
                </div>
                <div className="read-more">
                  <a href={blog.link} target="_blank" rel="noopener noreferrer">
                    <img src={mediumIcon} alt="Medium Icon" />
                    <Icon />
                  </a>
                </div>
              </div>
            </StyledBlog>
          ))}
        </div>
      </StyledBlogsSection>
    </section>
  );
};

export default Blogs;
