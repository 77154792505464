import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Vue.js',
    'Next.js',
    'React',
    'Remix',
    'TypeScript',
    'Ruby on Rails',
    'Python',
    'AWS',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              I'm a Software Engineer based in Chicago with 4+ years of full-stack experience.
              Currently at CardFree, I build essential platform features for major restaurant
              chains, enabling merchants to manage digital ordering, loyalty programs, real-time
              order tracking, and promotional campaigns across multiple locations.
            </p>

            <p>
              I am the co-creator of{' '}
              <a href="https://pennant-notebook.github.io" target="_blank" rel="noreferrer">
                Pennant
              </a>
              , an open-source computational notebook enabling collaborative coding and shared
              execution environments. It's powered by CRDTs and an asynchronous execution stack with
              support for Python and JavaScript. Try it <a href="https://trypennant.com">here</a>!
            </p>

            <p>
              I'm active in open source and recently contributed to{' '}
              <a href="https://marketplace.visualstudio.com/items?itemName=ZenML.zenml-vscode">
              ZenML Studio
              </a>
              , a VSCode extension for MLOps workflows. This project presented many intriguing challenges, 
              particularly around implementing a custom LSP server to bridge ZenML's Python library with TypeScript. The intuitive 
              interface we developed has been very well received by the ZenML community, and has been one of my most 
              rewarding and humbling experiences. You can read more about it <a href="https://www.zenml.io/blog/zenml-vscode-extension"> here</a>!
            </p>

            <p>
              When I'm not coding, you may find me deep diving into sound engineering and music
              production. If you're a fan of minimal deep techno, check out some of my tracks{' '}
              <a href="https://soundcloud.com/fidinmafishi/sets/mafishi-prod">here</a>!
            </p>

            <p>Here are some technologies I've been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/moi.png"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
